(function ($) {
    document.addEventListener('DOMContentLoaded', function() {
        
        // Check for banners and adjust body padding accordingly
        var countdownTimer = document.querySelector('.countdown-timer');
        var covidBanner = document.querySelector('.covid-banner-wrapper');
        var mainContent = document.getElementById('maincontent');
        
        // Check if we're on the homepage with splash
        var isHomeWithSplash = mainContent && 
                              (mainContent.classList.contains('full-width-splash-overflow') || 
                               document.querySelector('.full-width-splash') !== null);
        
        // Check if banners are visible
        var bannersVisible = (countdownTimer && countdownTimer.offsetHeight > 0) || 
                            (covidBanner && covidBanner.offsetHeight > 0);
        
        // Adjust body padding based on banner presence
        if (window.innerWidth >= 721) { // Only apply on desktop (matches the media query in _header-sticky.scss)
            if (bannersVisible) {
                // If banners exist, use 192px padding
                document.body.style.paddingTop = '192px !important';
            } else {
                // If no banners, use 160px padding
                document.body.style.paddingTop = '160px !important';
            }
        }
        
        // Add padding to main content when banners are visible AND we're not on homepage with splash
        if (!isHomeWithSplash && bannersVisible) {
            mainContent.style.paddingTop = '30px';
        }
        
        // Banner carousel functionality
        const covidScroll = document.querySelector('.covid-banner-scroll');
        const covidItems = document.querySelectorAll('.covid-banner');
        const covidPrevBtn = document.querySelector('.covid-prev');
        const covidNextBtn = document.querySelector('.covid-next');
        
        // Only proceed if we have the necessary elements
        if (!covidScroll || !covidItems.length) {
            return;
        }

        let covidCurrentIndex = 0;
        const covidTotalItems = covidItems.length;

        // Initialize first banner as active
        covidItems[0].classList.add('active');
        
        // Hide navigation if only one banner
        if (covidTotalItems <= 1) {
            if (covidPrevBtn) covidPrevBtn.style.display = 'none';
            if (covidNextBtn) covidNextBtn.style.display = 'none';
           
        } else {

        function updateCovidBanner(newIndex) {
            // Remove all state classes
            covidItems.forEach(item => {
                item.classList.remove('active', 'prev');
            });

            // Add appropriate classes for animation
            covidItems[covidCurrentIndex].classList.add('prev');
            covidItems[newIndex].classList.add('active');
            
            covidCurrentIndex = newIndex;
        }
        
        if (covidPrevBtn) {
            covidPrevBtn.addEventListener('click', function() {
                const prevIndex = (covidCurrentIndex - 1 + covidTotalItems) % covidTotalItems;
                updateCovidBanner(prevIndex);
            });
        }
        
        if (covidNextBtn) {
            covidNextBtn.addEventListener('click', function() {
                const nextIndex = (covidCurrentIndex + 1) % covidTotalItems;
                updateCovidBanner(nextIndex);
            });
        }

        // Auto scroll every 8 seconds
        const autoScroll = setInterval(function() {
            const nextIndex = (covidCurrentIndex + 1) % covidTotalItems;
            updateCovidBanner(nextIndex);
        }, 8000);
    }
        // Coupon modal functionality
        $(document).on('click', 'a[data-reveal-id="coupon-modal"]', function(e) {
            e.preventDefault();
            const banner = $(this).closest('.covid-banner');
            const modalContent = [];
            const code = banner.data('code');
            const endDate = new Date(banner.data('end-date')).toLocaleDateString();
            const minOrder = banner.data('minimum-order');
            const uses = banner.data('uses');
            const deliveryStart = new Date(banner.data('delivery-start')).toLocaleDateString();
            const deliveryEnd = new Date(banner.data('delivery-end')).toLocaleDateString();
            // Build modal content
            modalContent.push(`*Cannot be combined with other offers. Enter code <strong>${code}</strong> at the payment step in checkout to apply the discount and enjoy your savings.`);
            modalContent.push(`Coupon valid until ${endDate}`);

            const requirements = [];
            if (minOrder > 0) {
                requirements.push(`<li>Order total must meet minimum amount: <strong>$${minOrder}</strong></li>`);
            }
            if (uses > 0) {
                requirements.push(`<li>Available for the first <strong>${uses}</strong> customers</li>`);
            }
            if (deliveryStart && deliveryEnd) {
                if (deliveryStart === deliveryEnd) {
                    requirements.push(`<li>Exclusive for delivery/pick up date: <strong>${deliveryStart}</strong></li>`);
                } else {
                    requirements.push(`<li>Exclusive for deliveries/pickups between: <strong>${deliveryStart}</strong> and <strong>${deliveryEnd}</strong></li>`);
                }
            }
            // Add the requirements to the modal content only if there are any
            if (requirements.length > 0) {
                modalContent.push(`<strong>Eligibility Requirements:</strong><ul class="requirements-list">${requirements.join("")}</ul>`);
            }

            $('.banner-modal-content').html(modalContent.join("<br><br>"));
        });
    });
})(jQuery);